<template>
  <div class="flex flex-col gap-y-2">
    <div id="rodo-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="rodo-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">
              <div class="flex flex-none items-center">
                <input
                  id="filterInput"
                  @keyup.enter="executeAllFilters"
                  class="border-gray-300 dark:border-gray-600 rounded-r-none"
                  :class="{'bg-yellow-100 dark:bg-yellow-800': activeFilters.search.length > 0}"
                  v-model="activeFilters.search"
                  type="text"
                  placeholder="nazwisko lub pesel"
                >
                <button
                  @click="resetSearch"
                  class="tw--btn tw--btn-secondary rounded-none border-t border-b border-gray-300 dark:border-gray-600"
                  type="button"
                  :disabled="activeFilters.search === ''"
                  title="wyczyść"
                ><FontAwesomeIcon icon="times"/></button>
                <button
                  @click="executeAllFilters"
                  class="tw--btn border border-blue-600 dark:border-blue-900 rounded-l-none"
                  type="button"
                  :disabled="activeFilters.search === ''"
                >
                  Szukaj
                </button>
                <HelpContainer>
                  <strong>Pole wyszukuje klientów po nastepujących parametrach:</strong>
                  <ul>
                    <li>nazwisko</li>
                    <li>pesel</li>
                  </ul>
                </HelpContainer>
              </div>

              <BaseFilterSelectWithAddon
                @input="executeAllFilters"
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.reportType"
                label="Typ raportu"
                :options="reportList"
              />
            </div>
          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="initList" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-3 gap-y-1 justify-between">
        <button @click="executeAllFilters" class="tw--btn">Generuj raport</button>
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">znaleziono</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ pagiAllItems }} kartotek</span>
        </div>
      </div>
    </div>

    <div id="rodo-report-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex flex-wrap justify-between">
        <SortList v-if="sortedBy.field !== ''" @sort="sortItemsBy" :fields="sortFields" :selected="sortedBy" />

        <Pagination
          :current="pagiCurrentPage"
          :total="pagiTotalPages"
          @page="goToPage"
          @next="goToPage(pagiCurrentPage+1)"
          @prev="goToPage(pagiCurrentPage-1)"
          v-model="rowsOnPage"
          @perPageChanged="executeAllFilters"
        />
      </div>

      <div id="inkaso-report-table" class="flex flex-col gap-1">
        <div v-if="persons.length === 0">Brak wyników spełniających kryteria</div>
        <div class="flex flex-wrap border dark:border-gray-600 rounded shadow py-1" v-for="person in persons" :key="person.id">

          <div class="w-1/2 lg:w-3/12 order-1 flex-grow-0 flex items-center justify-start gap-x-2 px-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
            <router-link v-if="person.customer_id" :to="{ name: 'Customer', params: { id: person.customer_id } }" class="tw--link" target="_blank" title="otwórz do kartotekę klienta">
              <FontAwesomeIcon :icon="['far', 'user']" class="text-xl" />
            </router-link>
            <a v-else title="kartoteka klienta nie istnieje"><FontAwesomeIcon :icon="['far', 'user']" class="icon--muted text-xl" /></a>
            <router-link v-if="person.employee_id" :to="{ name: 'Employee', params: { id: person.employee_id } }" class="tw--link" target="_blank" title="otwórz do kartotekę pracownika">
              <FontAwesomeIcon :icon="['fas', 'user-tie']" class="text-xl" />
            </router-link>
            <a v-else title="kartoteka pracownika nie istnieje"><FontAwesomeIcon :icon="['fas', 'user-tie']" class="icon--muted text-xl" /></a>
            <span class="flex-shrink overflow-hidden overflow-ellipsis font-semibold text-bright">{{ person.full_name }}</span>
          </div>

          <div class="w-1/2 lg:w-1/6 order-3 flex gap-x-2 px-2 items-center">
            <span class="text-xs">pesel:</span>
            <span>{{ person.pesel_passport }}</span>
          </div>

          <div class="w-1/2 lg:w-1/6 order-4 flex items-center px-2">{{ person.full_address }}</div>

          <div class="w-1/2 lg:w-1/6 order-5 flex flex-wrap items-center gap-2 px-2">
            <div class="flex gap-x-2 items-center">
              <span class="text-xs">ofert:</span>
              <span class="text-bright font-semibold">{{ person.offers_no }}</span>
            </div>
            <div class="flex gap-x-2 items-center">
              <span class="text-xs">polis:</span>
              <span class="text-bright font-semibold">{{ person.contracts_no }}</span>
            </div>
          </div>

          <div class="flex-grow order-6 flex items-center px-2">
            <span v-if="person.admin_checked === undefined || person.admin_checked === ''" class="text-red-400 dark:text-red-600 font-semibold">Brak zgód</span>
            <span v-if="person.admin_checked === '0'" class="text-yellow-400 dark:text-yellow-600 font-semibold">Do akceptacji</span>
            <span v-if="person.admin_checked === '1'">
              {{ person.order_service }} | {{ person.email_order_service }} | {{ person.marketing }} | {{ person.telecom }} | {{ person.profiling }}<br>
              <span v-if="person.till_dismiss == 1" class="text-blue-500 dark:text-blue-700 font-semibold">Do odwołania</span>
              <span v-if="person.till_dismiss == 0 && person.due_date_h == ''" class="text-yellow-400 dark:text-yellow-600 font-semibold">Do końca umów</span>
              <span v-if="person.till_dismiss == 0 && person.due_date_h != ''">{{ person.due_date_h }}</span>
            </span>
          </div>

          <div v-if="showAdmin" class="w-1/2 lg:w-min order-2 lg:order-7 px-2 flex items-center justify-end">
            <button @click="anonimize(person.id)" class="tw--btn-icon-danger" title="Anonimizuj kartotekę">
              <FontAwesomeIcon :icon="['fas', 'user-slash']" class="text-xl" />
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapGetters, mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import ReportsService from '../../services/ReportsService';
import CustomerService from '../../services/CustomerService';
import Pagination from '../Pagination.vue';
import SortList from '../SortList.vue';

export default {
  name: 'ConsentsReport',
  components: {
    Pagination,
    SortList,
  },
  mixins: [confirm, errorHandler],
  data() {
    return {
      persons: [],
      sortedBy: {
        field: '',
        dir: 1,
      },
      activeFilters: {
        search: '',
        reportType: '',
      },
      pagiCurrentPage: 1,
      rowsOnPage: 20,
      pagiAllItems: 0,
      sortFields: [
        { id: 'name', name: 'nazwisko / nazwa' },
        { id: 'activeOffersNo', name: 'ilość ofert' },
        { id: 'activeContractsNo', name: 'ilość polis' },
      ],

      reportList: [
        { id: 'to-accept', name: 'Do akceptacji' },
        { id: 'no-orders', name: 'Bez zgód, bez ofert, bez polis' },
        { id: 'no-active-orders', name: 'Bez zgód i aktywnych ofert/polis' },
        { id: 'with-active-orders', name: 'Bez zgód z aktywnymi polisami' },
        // {id: 'after-due-date', name: 'Zgody po terminie'},
        // {id: 'almost-due-date', name: 'Zgody prawie po terminie'},
        { id: 'no-relations', name: 'Bez powiązań w bazie (klient/pracownik/os.kontakt.)' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'maxRowsOnPage',
      'currentUserType',
    ]),
    pagiTotalPages() {
      return Math.ceil(this.pagiAllItems / this.rowsOnPage);
    },
    personsSelected() {
      return this.persons.filter(obj => obj.checked === 1 || obj.checked === '1').length;
    },
    checkedAll() {
      return this.persons.find(obj => obj.checked === 0 || obj.checked === '0' || obj.checked === undefined) === undefined;
    },
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
  },
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      if (this.$route.query.sf !== undefined && this.sortFields.find(obj => obj.id === this.$route.query.sf) !== undefined) {
        this.sortedBy.field = this.$route.query.sf;
        if (this.$route.query.sd !== undefined) {
          this.sortedBy.dir = Number(this.$route.query.sd) === -1 ? -1 : 1;
        } else {
          this.sortedBy.dir = 1;
        }
      } else {
        this.sortedBy.field = 'name';
        this.sortedBy.dir = 1;
      }
      if (this.$route.query.p !== undefined && Number.isInteger(Number(this.$route.query.p)) && Number(this.$route.query.p) > 0) {
        this.pagiCurrentPage = Number(this.$route.query.p);
      }
      if (this.$route.query.rop !== undefined && Number.isInteger(Number(this.$route.query.rop)) && Number(this.$route.query.rop) > 0 && Number(this.$route.query.rop) <= this.maxRowsOnPage) {
        this.rowsOnPage = Number(this.$route.query.rop);
      } else {
        this.rowsOnPage = Number(this.pagiRowsOnPage);
      }
      // Filtry
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getPersons();
    },
    getPersons() {
      this.$store.dispatch('setLoadingData', true);
      if (this.activeFilters.search !== '' || this.activeFilters.reportType !== '') {
        const params = {
          sf: this.sortedBy.field, sd: this.sortedBy.dir, p: this.pagiCurrentPage, rop: this.rowsOnPage, ...this.activeFilters,
        };

        ReportsService.getRodoReport(params)
          .then((response) => {
            if (response.data.status === 'success') {
              this.persons = response.data.persons;
              this.pagiAllItems = Number(response.data.count_all_results);
              this.pagiCurrentPage = Number(response.data.curr_page);
              this.rowsOnPage = Number(response.data.rows_on_page);
              if (this.persons.length === 0) {
                this.$store.dispatch('notifications/add', { message: 'Raport został wygenerowany poprawnie. Brak danych spełniających podane kryteria', type: 'success', time: 6 });
              }
            } else {
              this.$store.dispatch('notifications/add', { message: 'Wystapił niespodziewany błąd!', type: 'danger', time: 6 });
            }
            this.$store.dispatch('setLoadingData', false);
            this.setUrl();
          })
          .catch((error) => {
            this.persons = [];
            this.$store.dispatch('setLoadingData', false);
            this.resolveError(error);
            this.setUrl();
          });
      } else {
        this.$store.dispatch('notifications/add', { message: "Wypełnij pole 'Szukaj' lub wybierz typ raportu, aby wygenerować wynik apełniający podane kryteria", type: 'warning', time: 6 });
        this.persons = [];
        this.$store.dispatch('setLoadingData', false);
        this.setUrl();
      }
    },
    sortItemsBy(object) {
      this.sortedBy.field = object.field;
      this.sortedBy.dir = object.dir;
      this.getPersons();
    },
    executeAllFilters() {
      this.pagiCurrentPage = 1;
      this.getPersons();
    },
    resetSearch() {
      this.activeFilters.search = '';
      this.getPersons();
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.getPersons();
    },
    goToPage(pageNumber) {
      if (Number.isInteger(Number(pageNumber)) && Number(pageNumber) !== this.pagiCurrentPage) {
        if (pageNumber >= 1 && pageNumber <= this.pagiTotalPages) {
          this.pagiCurrentPage = Number(pageNumber);
        } else if (pageNumber < 1) {
          this.pagiCurrentPage = 1;
        } else {
          this.pagiCurrentPage = Number(this.pagiTotalPages);
        }
        this.getPersons();
      }
    },
    setUrl() {
      const query = {
        sf: this.sortedBy.field,
        sd: this.sortedBy.dir,
        p: this.pagiCurrentPage,
        rop: this.rowsOnPage,
        search: encodeURI(this.activeFilters.search),
      };
      const queryFilters = {};
      Object.keys(this.activeFilters).forEach((key) => {
        queryFilters[key] = encodeURI(this.activeFilters[key]);
      });
      Object.assign(query, queryFilters);
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'RodoReport', query });
      }
    },
    checkAll() {
      if (this.checkedAll) {
        // eslint-disable-next-line no-param-reassign
        this.persons.forEach((obj) => { obj.checked = '0'; });
      } else {
        // eslint-disable-next-line no-param-reassign
        this.persons.forEach((obj) => { obj.checked = '1'; });
      }
    },
    anonimize(id) {
      this.confirm(() => this.confirmedAnonimize(id), 'UWAGA! Dane osobowe wybranej kartoteki zostaną zanonimizowane (dane osobowe takie jak imię, nazwisko zamazane znakami X, dane pesel/paszport, konto bank, telefon, email usunięte). Zostaną również usunięte wszystkie notatki i załączniki z powiązanych ofert/polis. Operacja jest nieodwracalna! Potwierdź lub anuluj operację.');
    },
    confirmedAnonimize(id) {
      this.$store.dispatch('setLoadingData', true);
      CustomerService.anonymizePersonalData(id)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getPersons();
            this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success', time: 6 });
          }
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          if (error.response !== undefined) {
            console.error(error.response.data.result);
            this.$store.dispatch('notifications/add', { message: error.response.data.msg, type: 'warning', time: 6 });
          } else {
            this.$store.dispatch('notifications/add', { message: 'Coś poszło nie tak...', type: 'danger', time: 6 });
            console.error(error);
          }
          this.$store.dispatch('setLoadingData', false);
        });
    },
  },
};
</script>

<style scoped>
.icon--muted {
  @apply text-gray-400 dark:text-gray-500;
}
</style>
